<template>
  <div>
    <procedure-detail-header
      v-if="loggedUser.role === 'professional'"
      no-survey-creation-button
      show-reload-resource
      :loading-reload-resource="loading.reloadData"
      @clickCreateFolder="isShow.folder_create=true"
      @clickCreateDocument="isShow.doc_create=true"
      @clickCreateSurvey="isShow.srv_create=true"
      @clickReloadResource="reloadData"
    />

    <procedure-card
      no-survey-icon
      :procedure-obj="procedureData"
      :procedure-enter-btn="false"
      :redirect-to-detail="true"
    />

    <v-divider></v-divider>
    <!-- FOLDERS -->
    <v-row class="mt-3">
      <v-col
        v-for="(folder,idx) in level0Folders"
        :key="'F'+idx"
        cols="12"
        sm="12"
        md="3"
      >
        <folder-card
          folder-route="procedureFolderView"
          :folder-obj="folder"
          :folder-permissions="folder.folder_permissions"
          :go-to-folder-target-id="folder.procedure_id"
          :api-target-id="folder.procedure_id"
          :api-routes="apiRoutes"
          @reloadData="reloadData"
          @droppedItem="setIsDropped"
        />
      </v-col>
    </v-row>

    <!--- DOCUMENTS -->
    <v-row>
      <v-col
        v-for="(doc,idx) in level0Documents"
        :key="'D'+idx"
        cols="12"
        sm="12"
        md="4"
      >
        <document-card
          can-handle-resource
          is-draggable
          :procedure-id="procedureData.id"
          :document-obj="doc"
          :is-dropped="isDropped[doc.id]"
          @reloadData="reloadData"
        />
      </v-col>

      <!--- SURVEYS -->
      <v-col
        v-for="(srv,idx) in procedureData.surveys"
        :key="'S'+idx"
        cols="12"
        sm="12"
        md="4"
      >
        <survey-card
          :survey-obj="srv"
          @reloadData="reloadData"
        />
      </v-col>
    </v-row>

    <create-folder
      :is-show="isShow.folder_create"
      @close="isShow.folder_create=false"
      @reload-data="reloadData"
    />
    <create-document
      :is-show="isShow.doc_create"
      @close="isShow.doc_create=false"
      @reload-data="reloadData"
    />
    <create-survey
      :is-show="isShow.srv_create"
      @close="isShow.srv_create=false"
      @reload-data="reloadData"
    />
  </div>
</template>

<script>
import ProcedureDetailHeader from '@/components/section-headers/procedure-detail-header.vue'
import ProcedureCard from '@/components/cards/procedure-card.vue'

import FolderCard from '@/components/cards/folder-card.vue'
import CreateFolder from '@/components/modals/create-folder.vue'

import DocumentCard from '@/components/cards/document-card.vue'
import CreateDocument from '@/components/modals/create-document.vue'

import SurveyCard from '@/components/cards/survey-card.vue'
import CreateSurvey from '@/components/modals/create-survey.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    ProcedureDetailHeader,
    ProcedureCard,
    FolderCard,
    CreateFolder,
    CreateDocument,
    CreateSurvey,
    DocumentCard,
    SurveyCard,
  },
  data() {
    return {
      isDropped: {},
      isShow: {
        folder_create: false,
        doc_create: false,
        srv_create: false,
      },
      loading: {
        reloadData: false,
      },
      procedureData: {},
      mapExpirationChip: {
        expired: 'Scaduta',
      },
      apiRoutes: {
        editApiMethod: 'editFolder',
        deleteApiMethod: 'destroyFolder',
        editDocumentApiMethod: 'editDocument',
        permGetApiMethod: 'getFolderPermissions',
        permSetApiMethod: 'setFolderPermissions',
        permEditApiMethod: 'updateFolderPermission',
        permDeleteApiMethod: 'deleteFolderPermission',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList']),
    level0Folders() {
      if (this.procedureData.folders) {
        return this.procedureData.folders.filter(item => item.parent_id === 0)
      }

      return []
    },
    level0Documents() {
      if (this.procedureData.documents) {
        return this.procedureData.documents.filter(item => item.folder_id === 0)
      }

      return []
    },
  },
  watch: {
    procedureList() {
      this.getData()
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async reloadData() {
      this.loading.reloadData = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('getProcedure', this.procedureData.id)
          this.procedureData = await this.procedureList.find(item => item.id === this.procedureData.id)
          this.isDropped = []
        } catch (error) {
          console.log(error)
        }
        this.loading.reloadData = false
      }, 1000)
    },
    async getData() {
      const procedureId = parseInt(this.$route.params.id, 10)
      this.procedureData = await this.procedureList.find(item => item.id === procedureId)
    },
    goToList() {
      this.$router.push({ name: 'procedure' })
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
  },
}
</script>

<style scoped>

</style>
