<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aggiungi un questionario</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="form.priority"
                  :items="priorityOpts"
                  value="standard"
                  label="Priorità"
                  outlined
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="form.company_status_id"
                  :items="companySettingsFilteredList('survey.status').map(sta => ({ text: sta.name, value: sta.id }))"
                  value="1"
                  label="Stato"
                  outlined
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'SurveyCreate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialForm: {},
      form: {},
      priorityOpts: [
        {
          text: 'Normale',
          value: 'standard',
        },
        {
          text: 'Bassa priorità',
          value: 'low',
        },
        {
          text: 'Urgente',
          value: 'high',
        },
      ],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'companySettingsFilteredList']),
  },
  mounted() {
    this.setInitialValuesFromSettings()
    this.setInitialValues()
  },
  methods: {
    setInitialValuesFromSettings() {
      const settings = ['status']
      settings.forEach(setting => {
        this.companySettingsFilteredList(`survey.${setting}`).map(act => {
          if (act.is_default === 1) {
            const kset = `company_${setting}_id`
            this.initialForm[kset] = act.id
          }
        })
      })
    },
    setInitialValues() {
      this.form = {
        ...this.initialForm,
      }
    },
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.createSurveyProcedure(this.$route.params.id, this.form)
          this.isShow = false
          await this.$store.dispatch('set_alert', { msg: 'Questionario creato con successo', type: 'success' })
          this.$emit('reload-data')
          await this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async closeModal() {
      await this.$refs.form.reset()
      await this.$emit('close')
      await this.setInitialValues()
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
